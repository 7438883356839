import React from "react";
import { useNavigate } from "react-router-dom";
import WilsonHeadIcon from '../images/wilson-head-icon.svg';
import WebsitesIcon from '../images/websites.svg';
import ChevronUp from '../images/chevron-up.svg';
import '../styles/style.css';

const Portfolio = () => {
    const navigate = useNavigate();

    return (
        <div className="main">
            <div className="card">
                <div className="portfolio">
                    <p>
                        Portfolio
                    </p>
                    <div className="portfolio-items">
                        <div className="big-button rounded clickable tooltip-under" onClick={() => { navigate('dstmods') }}>
                            <img src={WilsonHeadIcon} alt="DST" />
                            <div className='tooltip'>
                                <p>
                                    Don't starve Mods
                                </p>
                            </div>
                        </div>
                        <div className="big-button rounded clickable tooltip-under" onClick={() => { navigate('websites') }}>
                            <img src={WebsitesIcon} alt="Websites" />
                            <div className='tooltip'>
                                <p>
                                    Websites
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="small-button rounded clickable" onClick={() => { navigate(-1) }}>
                    <img src={ChevronUp} alt="Up" />
                </div>
            </div>
        </div>
    );
}

export default Portfolio;