import React, { useEffect } from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import ChevronLeft from '../images/chevron-left.svg';
import ChevronRight from '../images/chevron-right.svg';

type SliderProps = {
    children: any;
}

type SliderItemProps = {
    children: any;
    width?: string;
    onClick?: () => void;
}

export const SliderItem = (props: SliderItemProps) => {
    return (
        <div className='slider-item' style={{
            'width': props.width
        }} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

const Slider = (props: SliderProps) => {
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [isZoomed, setZoom] = useState<boolean>(false);

    const nextSlide = () => {
        if (selectedItem < props.children.length - 1) setSelectedItem(selectedItem + 1)
    }

    const previousSlide = () => {
        if (selectedItem > 0) setSelectedItem(selectedItem - 1)
    }

    const selectSlide = (id: number) => {
        if (selectedItem !== id) setSelectedItem(id);
    }

    return (
        <div className={`slider`}>
            <div className='inner' style={{
                'transform': `translateX(${-(selectedItem * 100)}%)`
            }}>
                {React.Children.map(props.children, (child, index) => {
                    return React.cloneElement(child, { width: '100%', onClick: () => !isZoomed ? setZoom(true) : setZoom(false) })
                })}
            </div>
            <div className="button clickable rounded" style={{ position: 'absolute', left: '10px' }} onClick={previousSlide}>
                <img src={ChevronLeft} alt="Left" />
            </div>
            <div className="button clickable rounded" style={{ position: 'absolute', right: '10px' }} onClick={nextSlide}>
                <img src={ChevronRight} alt="Right" />
            </div>
            <div className="image-picker-bar">
                {React.Children.map(props.children, (child, index) => {
                    return <span className={`image-picker${selectedItem === index ? ' clicked' : ' clickable'}`} key={`image-picker-${index})}`} onClick={() => selectSlide(index)} />
                })}
            </div>
            {createPortal(
                <div className={`slider portal${isZoomed ? ' zoomed' : ''}`}>
                    <div className='inner' style={{
                        'transform': `translateX(${-(selectedItem * 100)}%)`
                    }}>
                        {React.Children.map(props.children, (child, index) => {
                            return React.cloneElement(child, { width: '100%', onClick: () => !isZoomed ? setZoom(true) : setZoom(false) })
                        })}
                    </div>
                    <div className="button clickable rounded" style={{ position: 'absolute', left: '10px' }} onClick={previousSlide}>
                        <img src={ChevronLeft} alt="Left" />
                    </div>
                    <div className="button clickable rounded" style={{ position: 'absolute', right: '10px' }} onClick={nextSlide}>
                        <img src={ChevronRight} alt="Right" />
                    </div>
                    <div className="image-picker-bar">
                        {React.Children.map(props.children, (child, index) => {
                            return <span className={`image-picker${selectedItem === index ? ' clicked' : ' clickable'}`} key={`image-picker-${index})}`} onClick={() => selectSlide(index)} />
                        })}
                    </div>
                </div>, document.querySelectorAll('#root')[0]
            )}
        </div>
    )
}

export default Slider;