import HtmlIcon from '../images/html.svg';
import CssIcon from '../images/css.svg';
import JsIcon from '../images/js.svg';
import ReactIcon from '../images/react.svg';
import JavaIcon from '../images/java.svg';
import ChevronUp from '../images/chevron-up.svg';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow, subYears } from 'date-fns';

const Bio = () => {
    const navigate = useNavigate();

    return (
        <div className="main">
            <div className="card">
                <div className="bio">
                    <p>
                        Bio
                    </p>
                    <p>
                        Hey, my name is Michał but you can call me Aurorus, I am programmer from Poland who is interested in web technologies like React JS and web design using Figma as design software. I also like linux systems, I mostly use them on servers but I don't mind using desktop ones. Another of my things are video games and spending time with friends, playing or chatting. I like video games where you have freedom in what you want to create like minecraft what was my first experience with games modding (Spigot plugins). I am familiar with creating and managing communities like minecraft and discord servers as I have some where I am owner, admin or plugin developer.
                    </p>
                </div>
                <div className="languages">
                    <div className="button rounded tooltip-under" style={{ backgroundColor: '#F54A00' }}>
                        <img src={HtmlIcon} alt="HTML Icon" />
                        <div className='tooltip'>
                            <p>
                                Learning for {formatDistanceToNow(subYears(new Date('2018-01-01'), 0))}
                            </p>
                        </div>
                    </div>
                    <div className="button rounded tooltip-under" style={{ backgroundColor: '#006383' }}>
                        <img src={CssIcon} alt="CSS Icon" />
                        <div className='tooltip'>
                            <p>
                            Learning for {formatDistanceToNow(subYears(new Date('2018-01-01'), 0))}
                            </p>
                        </div>
                    </div>
                    <div className="button rounded tooltip-under" style={{ backgroundColor: '#FFB800' }}>
                        <img src={JsIcon} alt="JS Icon" />
                        <div className='tooltip'>
                            <p>
                            Learning for {formatDistanceToNow(subYears(new Date('2019-09-01'), 0))}
                            </p>
                        </div>
                    </div>
                    <div className="button rounded tooltip-under" style={{ backgroundColor: '#00B091' }}>
                        <img src={ReactIcon} alt="React Icon" />
                        <div className='tooltip'>
                            <p>
                            Learning for {formatDistanceToNow(subYears(new Date('2021-12-14'), 0))}
                            </p>
                        </div>
                    </div>
                    <div className="button rounded tooltip-under" style={{ backgroundColor: '#F54A00' }}>
                        <img src={JavaIcon} alt="Java Icon" />
                        <div className='tooltip'>
                            <p>
                            Learning for {formatDistanceToNow(subYears(new Date('2019-11-01'), 0))}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="small-button rounded clickable" onClick={() => { navigate(-1) }}>
                    <img src={ChevronUp} alt="Up" />
                </div>
            </div>
        </div>
    );
};

export default Bio;