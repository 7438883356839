import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Bio from "../scenes/Bio";
import DstMods from "../scenes/DstMods";
import Error404 from "../scenes/Errors/404";
import Home from "../scenes/Home";
import Portfolio from "../scenes/Portfolio";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='bio' element={<Bio />} />
                <Route path='portfolio' element={<Portfolio />} />
                <Route path='portfolio/dstmods' element={<DstMods />} />
                <Route path='*' element={<Error404 />} />
            </Routes>
        </Router>
    )
};

export default AppRoutes;