import React, { useState } from "react";
import '../styles/style.css';
import Avatar from '../images/avatar.png'
import StatusIcon from '../images/pawprint.png';
import Steam from '../images/steam.svg';
import Discord from '../images/discord.svg';
import Github from '../images/github.svg';
import Informations from '../images/informations.svg';
import Folder from '../images/folder.svg';
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow, subYears } from "date-fns";

const Home = () => {
    const defaultDiscordTag: string = 'aurorus';

    const navigate = useNavigate();
    const [discordTag, setDiscordTag] = useState<string>(defaultDiscordTag);

    return (
        <div className="main">
            <div className="card">
                <div className="avatar-container">
                    <img src={Avatar} alt="avatar" className="rounded avatar" />
                    <div className="rounded status">
                        <img src={StatusIcon} alt="Status Icon" />
                    </div>
                </div>
                <div className="basic-info">
                    <p>
                        Aurorus
                    </p>
                    <p>
                        Programmer/Poland/{formatDistanceToNow(subYears(new Date('2004-04-14'), 0)).replace(/\D+/g, '')}
                    </p>
                </div>
                <div className="socials">
                    <div className="button rounded clickable tooltip-under" onClick={async () => {
                        if (discordTag !== 'Copied to clipboard!') {
                            await navigator.clipboard.writeText(discordTag);
                            setDiscordTag('Copied to clipboard!')
                            setTimeout(() => {
                                setDiscordTag(defaultDiscordTag);
                            }, 2000)
                        }
                    }}>
                        <img src={Discord} alt="Discord" />
                        <div className='tooltip'>
                            <p>
                                {discordTag}
                            </p>
                        </div>
                    </div>
                    <div className="button rounded clickable" onClick={() => { window.open('https://steamcommunity.com/id/auroruspl/', '_blank') }}>
                        <img src={Steam} alt="Steam" />
                    </div>
                    <div className="button rounded clickable" onClick={() => { window.open('https://github.com/AurorusFur', '_blank') }}>
                        <img src={Github} alt="Github" />
                    </div>
                    <div className="button rounded clickable tooltip-under" onClick={() => { navigate('/bio') }}>
                        <img src={Informations} alt="Informations" />
                        <div className='tooltip'>
                            <p>
                                Bio
                            </p>
                        </div>
                    </div>
                    <div className="button rounded clickable tooltip-under" onClick={() => { navigate('/portfolio') }}>
                        <img src={Folder} alt="Portfolio" />
                        <div className='tooltip'>
                            <p>
                                Portfolio
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;