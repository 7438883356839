import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Commissions from '../images/commisions.svg'
import Folder from '../images/folder.svg'
import ChevronUp from '../images/chevron-up.svg';
import '../styles/style.css';
import Slider, { SliderItem } from "../components/Slider";
import NenoSliderImage1 from '../images/sliderContent/neno1.jpg';
import NenoSliderImage2 from '../images/sliderContent/neno2.jpg';
import NenoSliderImage3 from '../images/sliderContent/neno3.jpg';

const DstMods = () => {
    const imagesTable = [
        <div className="dst-mods" key={'content-images'}>
            <p>
                DST Mods / Images
            </p>
            <Slider>
                <SliderItem>
                    <img src={NenoSliderImage1} alt="Tox as a don't starve character" />
                </SliderItem>
                <SliderItem>
                    <img src={NenoSliderImage2} alt="Pysiek as a don't starve character" />
                </SliderItem>
                <SliderItem>
                    <img src={NenoSliderImage3} alt="Pirqoo as a don't starve character" />
                </SliderItem>
            </Slider>
            <div className="dst-mods-items">
                <div className="button rounded clicked tooltip-under">
                    <img src={Folder} alt="Images" />
                    <div className='tooltip'>
                        <p>
                            Images
                        </p>
                    </div>
                </div>
                <div className="button rounded clickable tooltip-under">
                    <img src={Commissions} alt="Commissions" onClick={() => { setContent(commissionsTable) }} />
                    <div className='tooltip'>
                        <p>
                            Pricing
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ];
    const commissionsTable = [
        <div className="dst-mods" key={'content-commissions'}>
            <p>
                DST Mods / Commissions
            </p>
            <p>
                Pricing
            </p>
            <table className="pricing">
                <tbody>
                    <tr>
                        <td>
                            Base Character
                        </td>
                        <td>
                            Starts with 70 Euro
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Character Port DST to DS
                        </td>
                        <td>
                            Starts with 10 Euro
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Custom Item/Food
                        </td>
                        <td>
                            Starts with 7 Euro
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="dst-mods-items">
                <div className="button rounded clickable tooltip-under" onClick={() => { setContent(imagesTable) }}>
                    <img src={Folder} alt="Images" />
                    <div className='tooltip'>
                        <p>
                            Images
                        </p>
                    </div>
                </div>
                <div className="button rounded clicked tooltip-under">
                    <img src={Commissions} alt="Commisions" />
                    <div className='tooltip'>
                        <p>
                            Pricing
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ];
    const navigate = useNavigate();
    const [content, setContent] = useState(imagesTable);

    return (
        <div className="main">
            <div className="card">
                {content}
                <div className="small-button rounded clickable" onClick={() => { navigate(-1) }}>
                    <img src={ChevronUp} alt="Up" />
                </div>
            </div>
        </div>
    );
}

export default DstMods;