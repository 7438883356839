import '../../styles/style.css';
import ChevronUp from '../../images/chevron-up.svg';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
    const navigate = useNavigate();

    return (
        <div className="main" style={{ 'flexDirection': 'column', 'fontSize': '48px', 'color': 'white', 'fontWeight': 'bold', 'position': 'relative' }}>
            <div className="rounded error">
                <p>
                    404
                </p>
                <p>
                    Not Found
                </p>
            </div>
            <div className="small-button rounded clickable" style={{ 'position': 'absolute', 'bottom': '12px' }} onClick={() => {
                navigate(-1);
            }}>
                <img src={ChevronUp} alt="Up" />
            </div>
        </div>
    );
}

export default Error404;