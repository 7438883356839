import { IOptions, RecursivePartial } from "tsparticles";

const particlesSettings: RecursivePartial<IOptions> = {
  background: {
    color: {
      value: "#292B39"
    },
    position: "50% 50%",
    repeat: "no-repeat",
    size: "cover"
  },
  fullScreen: {
    zIndex: 1
  },
  interactivity: {
    events: {
      onClick: {
        mode: "repulse"
      },
      onHover: {
        mode: "bubble"
      }
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 0.3,
        opacity: 1,
        size: 4
      },
      grab: {
        distance: 400,
        links: {
          opacity: 0.5
        }
      }
    }
  },
  particles: {
    color: {
      value: "#c2eaff"
    },
    links: {
      color: {
        value: "#ffffff"
      },
      distance: 500,
      opacity: 0.4,
      width: 2
    },
    move: {
      attract: {
        rotate: {
          x: 600,
          y: 1200
        }
      },
      direction: "bottom",
      enable: true,
      path: {},
      outModes: {
        bottom: "out",
        left: "out",
        right: "out",
        top: "out",
        default: 'out',
      },
      spin: {}
    },
    number: {
      density: {
        enable: true,
        area: 1000
      },
      value: 400
    },
    opacity: {
      random: {
        enable: true,
        minimumValue: 0.1,
      },
      value: {
        min: 0.1,
        max: 0.5
      },
      animation: {
        speed: 1,
        minimumValue: 0.1
      }
    },
    size: {
      random: {
        enable: true,
        minimumValue: 1,
      },
      value: {
        min: 1,
        max: 2
      },
      animation: {
        speed: 40,
        minimumValue: 0.1
      }
    }
  }
}

export default particlesSettings;